import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#00A0FF',
  success: '#045692',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  orange: '#009cff',
  info: '#6BBC5E',
  red: '#C10000',
  edit: '#009cff',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
