import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
        // Jantes
        {
          name: 'Jantes',
          path: '/product/list-jantes',
          component: () => import('@/views/dashboard/product/ListJantes'),
        },
        {
          name: 'Ajout jantes',
          path: '/product/add-jantes',
          component: () => import('@/views/dashboard/product/AddJantes'),
        },
        // One Jantes
        {
          name: 'JantesDetails',
          path: '/product/jantes-details',
          component: () => import('@/views/dashboard/product/OneJantes'),
        },
        // Pneu
        {
          name: 'Pneu',
          path: 'product/list-pneu',
          component: () => import('@/views/dashboard/product/ListPneu'),
        },
        // Vehicule
        {
          name: 'Vehicule',
          path: 'vehicule/list-vehicule',
          component: () => import('@/views/dashboard/vehicule/ListVehicule'),
        },
        // One Vehicule
        {
          name: 'VehiculeDetails',
          path: 'vehicule/vehicule-details',
          component: () => import('@/views/dashboard/vehicule/OneVehicule'),
        },
        // Commande
        {
          name: 'Commandes',
          path: 'commande/list-commande',
          component: () => import('@/views/dashboard/commande/ListCommande'),
        },
        // Commande Details
        {
          name: 'CommandeDetails',
          path: 'commande/commande-details',
          component: () => import('@/views/dashboard/commande/OneCommande'),
        },
        // Liste utilisateur
        {
          name: 'ListUsers',
          path: 'users/list-users',
          component: () => import('@/views/dashboard/users/ListUsers'),
        },
        {
          name: 'UsersDetails',
          path: 'users/users-details',
          component: () => import('@/views/dashboard/users/OneUser'),
        },
        // Promotions
        {
          name: 'Promotion',
          path: 'promotions/promotion',
          component: () => import('@/views/dashboard/promotion/Promotion'),
        },
        // Promotions Details
        {
          name: 'PromotionDetails',
          path: 'promotions/promotion-details',
          component: () => import('@/views/dashboard/promotion/PromotionDetails'),
        },
        {
          name: 'Parametres',
          path: 'parametres/parametre',
          component: () => import('@/views/dashboard/parametres/Parametre'),
        },
        {
          name: 'GestionCouleurs',
          path: 'parametres/GestionCouleurs',
          component: () => import('@/views/dashboard/parametres/GestionCouleur'),
        },
        {
          name: 'GestionTypeVehicule',
          path: 'parametres/GestionTypeVehicule',
          component: () => import('@/views/dashboard/parametres/GestionTypeVehicule'),
        },
        {
          name: 'Formulaires',
          path: 'formulaire/list-formulaire',
          component: () => import('@/views/dashboard/formulaire/ListFormulaire'),
        },
        {
          name: 'FormulaireDetails',
          path: 'formulaire/formulaire-details',
          component: () => import('@/views/dashboard/formulaire/OneFormulaire'),
        },
      ],
      // {
      //   name: 'PageDeConnexion',
      //   path: 'connexion',
      //   component: () => import('@/views/dashboard/connexion/Connexion'),
      // },
    },
    {
    path: '/connexion',
    component: () => import('@/views/dashboard/connexion/Connexion'),
    },
  ],
})
