<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    created () {
      if (this.$localStorage.get('connected') == null) {
        this.$router.push({ path: '/connexion' })
      }
    },
  }
</script>
<style>
.v-sheet.v-card:not(.v-sheet--outlined){
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1)) !important;
  box-shadow: none !important;
}
th.primary--text {
  font-weight: 900 !important;
  font-size: 1rem !important;
  text-transform: uppercase;
}
.page-link {
  background: #fff;
  margin-right: 10px;
  border-radius: 5px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1)) !important
}
.theme--light .v-content {
    background-color: #F5F6FA;
}
</style>
