// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueLocalStorage from 'vue-localstorage'
import VueGoogleCharts from 'vue-google-charts'
import VueCookie from 'vue-cookie'
import VueTagManager from 'vue-tag-manager'

import JwPagination from 'jw-vue-pagination'
Vue.component('jw-pagination', JwPagination)

Vue.config.productionTip = false
Vue.use(VueLocalStorage)
Vue.use(VueGoogleCharts)
Vue.use(VueCookie)

Vue.use(VueTagManager, {
  gtmId: 'GTM-WFRPFVQ',
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
